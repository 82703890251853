import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValorPixComponent } from './valor-pix.component';
import { CompartilhadoModule } from 'src/app/compartilhado/compartilhado.module';



@NgModule({
  declarations: [ValorPixComponent],
  imports: [
    CommonModule,
    CompartilhadoModule
  ],
  exports:[
    ValorPixComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class ValorPixModule { }
