import { Component, OnInit, HostListener } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { slideInAnimation } from 'src/app/animations';
import { GlobalService } from 'src/app/providers/global.service';

declare function chatAtendimento(): any;

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AdminLayoutComponent implements OnInit {
  isMobileResolution: boolean;

  constructor(
    public gs: GlobalService, private contexts: ChildrenOutletContexts
  ) {
    this.gs.verificaAutenticado();
    if (this.gs.souExecutivo() || this.gs.souFranqueado() || this.gs.souEstabelecimento()) {
      // chatAtendimento();
    }
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }
  @HostListener('window:resize', ['$event'])
  isMobile(event) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }
  
  ngOnInit() { }

  getRouteAnimationData() {
      return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
