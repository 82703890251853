import { Component, HostListener, ViewChild } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ResolveEnd } from '@angular/router';
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import { GlobalService } from "./providers/global.service";
import { UsuarioService } from "./services/usuario.service";
import { ClipboardService } from 'ngx-clipboard';
import * as $ from 'jquery';
import { AngularFireMessaging } from "@angular/fire/messaging";
import { MessagingService } from "./services/push.service";

declare var window;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {

  @ViewChild('modalIndicacao') public modalIndicacao: ModalDirective;
  @ViewChild('modalAddSaldo') public modalSaldo: ModalDirective;


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      let width;
      width = window.innerWidth;
      if (width > 576) {
        this.gs.isMobile = false;
      } else {
        this.gs.isMobile = true;
      }
    }, 50);
  }

  constructor(
    private router: Router, public gs: GlobalService, private modalService: BsModalService,
    private uSvc: UsuarioService, private clipboard: ClipboardService, private afMessaging: AngularFireMessaging,
    private messagingService: MessagingService
  ) {
    this.escutarPush();
    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {
        // Show loading indicator
        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        if (this.gs.souEstabelecimento() || this.gs.souFranqueado() || this.gs.souExecutivo()) {
          if (router.url === '/dashboard') {
            //$('.b24-widget-button-inner-container').show();
            $('.woot-widget-holder, .woot--bubble-holder').show();
          } else {
            //$('.b24-widget-button-inner-container').hide();
            $('.woot-widget-holder, .woot--bubble-holder').hide();
          }
        } else {
          $('.woot-widget-holder, .woot--bubble-holder').hide();
        }
      }

      if (event instanceof ResolveEnd) {
        // Hide loading indicator
        setTimeout(() => {
          if (this.gs.souEstabelecimento() || this.gs.souFranqueado() || this.gs.souExecutivo()) {
            if (router.url === '/dashboard') {
              //$('.b24-widget-button-inner-container').show();
              $('.woot-widget-holder, .woot--bubble-holder').show();
            } else {
              //$('.b24-widget-button-inner-container').hide();
              $('.woot-widget-holder, .woot--bubble-holder').hide();
            }
          } else {
            $('.woot-widget-holder, .woot--bubble-holder').hide();
          }

        }, 500);
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        // console.log(event.error);
      }
    });


    setTimeout(() => {
      this.onResize(null);
    }, 50);

    window.app = this;
  }


  escutarPush() {
    // this.afMessaging.messages
    //   .subscribe((message) => { console.log(message); });
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
  }


  formIndicacao: BsModalRef;
  formCfgIndicacao = {
    keyboard: true,
    class: "modal-dialog-centered modal-lg",
    ignoreBackdropClick: true,
  };
  dadosIndicador;
  mIndicacao() {
    const initialState = { ind: '' };
    // this.formIndicacao = this.modalService.show(this.modalIndicacao, Object.assign({}, this.formCfgIndicacao, { initialState }));
    // console.log(o);
    if (!this.dadosIndicador) {
      this.gs.animacao = true;
      this.uSvc.indicacaoDados().subscribe(
        (r) => {
          if (r.status === 200) {
            this.dadosIndicador = r.obj;
            // console.log(this.dadosIndicador);
            // console.log(this.rec);
            this.formIndicacao = this.modalService.show(this.modalIndicacao, Object.assign({}, this.formCfgIndicacao, { initialState }));
          }
        },
        null,
        () => {
          this.gs.animacao = false;
        });
    } else {
      this.formIndicacao = this.modalService.show(this.modalIndicacao, Object.assign({}, this.formCfgIndicacao, { initialState }));
    }

  }

  formAddSaldo: BsModalRef;
  formCfgAddSaldo = {
    keyboard: true,
    class: "modal-dialog-centered modal-sm",
    ignoreBackdropClick: true,
  };
  mSaldo() {
    this.formAddSaldo = this.modalService.show(this.modalSaldo, this.formCfgAddSaldo);
  }

  copiarLinkIndicacao() {
    this.clipboard.copy(this.dadosIndicador.linkIndicacao);
    this.gs.showNotification('success', 'Link de Indicação', 'Link de indicação copiado para área de transferência', 3000);
  }

}
