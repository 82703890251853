import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as crypto from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { UsuarioService } from '../services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  usuario: any;
  tempoAuth = null;
  dataUltimaTransacao = null;
  isMobile = false;
  ctLogin = 0;

  linkPartner = '';

  webToken = '';
  webTokenGravado = false;

  barraUpload = {
    show: false,
    arquivo: '',
    pct: 0
  };

  private storage: Storage;

  constructor(
    public router: Router, private toastr: ToastrService, private ck: CookieService,
  ) {
    this.storage = window.localStorage;
    if (this.tempoAuth === null) {
      this.tempoAuth = setInterval(() => {
        this.verificaAutenticado();
      }, 1000);
    }
    try {
      this.usuario = this.pegaDados('dadosUsuario');
    } catch (ex) { }
  }

  animacao: boolean = false;

  verificaAutenticado() {
    if (this.router.url.indexOf('cadastro') > -1) {
      // this.router.navigate(['/cadastro']);
    } else if (this.router.url !== '/login' && this.router.url !== '/termos' && this.router.url !== '/termos/cliente' && this.router.url !== '/esquecisenha' 
    && this.router.url !== '/cadastro' && this.router.url !== '/termos/estabelecimento' && this.router.url !== '/termos/executivo' && this.router.url !== '/esqueci-senha' &&
    this.pegaDados('dadosUsuario') === null) {
      this.router.navigate(['/login']);
    }
  }

  gravaDados(chave: string, valor: any): void {
    // sessionStorage.setItem(chave, JSON.stringify(valor));
    // // this.storage.setItem(chave, JSON.stringify(valor));
    try {
      this.ck.set(chave, valor.token);
      this.storage.setItem(chave, JSON.stringify(valor.usuario));
      this.usuario = valor;
    } catch (ex) { }
  }

  pegaDados(chave: string): any {
    // return JSON.parse(sessionStorage.getItem(chave));
    // // return JSON.parse(this.storage.getItem(chave));
    let v: any;
    if (this.ck.check(chave)) {
      v = {};
      v.token = this.ck.get(chave);
      v.usuario = JSON.parse(this.storage.getItem(chave));
    }
    if (v) {
      return v;
      // return JSON.parse(this.ck.get(chave));
    } else {
      return null;
    }
  }

  apagaDados(chave: string): void {
    // sessionStorage.removeItem(chave);
    // // this.storage.removeItem(chave);
    this.usuario = null;
    this.ck.delete(chave);
    this.storage.removeItem(chave);
  }

  souEstabelecimento() {
    let r = false;
    try {
      this.usuario.usuario.tipos.forEach((i) => {
        if (i === 3) {
          r = true;
        }
      });
    } catch (x) { }
    return r;
  }

  souFuncionario() {
    return this.usuario.usuario.isFuncionario;
  }

  souFreemium() {
    let r = false;
    try {
      this.usuario.usuario.tipos.forEach((i) => {
        if (i === 4) {
          r = true;
        }
      });
    } catch (x) { }
    return r;
  }

  souExecutivo() {
    let r = false;
    try {
      this.usuario.usuario.tipos.forEach((i) => {
        if (i === 2) {
          r = true;
        }
      });
    } catch (x) { }
    return r;
  }

  souFranqueado() {
    let r = false;
    try {
      this.usuario.usuario.tipos.forEach((i) => {
        if (i === 1) {
          r = true;
        }
      });
    } catch (x) { }
    return r;
  }

  souAdministrador() {
    let r = false;
    try {
      this.usuario.usuario.tipos.forEach((i) => {
        if (i === 5) {
          r = true;
        }
      });
    } catch (x) { }
    return r;
  }

  souUsuarioComum() {
    let r = false;
    try {
      this.usuario.usuario.tipos.forEach((i) => {
        if (i === 0) {
          r = true;
        }
      });
    } catch (x) { }
    return r;
  }


  criptografaDados(str: string): string {
    let login = this.usuario.usuario.cpfCnpj.replace(/\./gi, '').replace('/', '').replace('-', '');

    const data = new Date();
    // a nova vai ser Nível$!8t@2@!#&¨&$-##$
    //let chave = 'Nível!!#@#@#&¨&$###$' + login; // (this.eNumero(login) ? parseInt(login.substr(0, 5)) : login.length);
    let chave = 'Nível$!8t@2@!#&¨&$-##$' + login;
    let dd = (data.getUTCFullYear() * (data.getUTCMonth() + 1) * (data.getUTCDate()));// * data.getUTCHours());
    chave = chave + dd;
    let salto = chave + '@Niv3L';

    // console.log(salto);

    let ret: string;
    ret = (crypto.AES.encrypt((str), salto)).toString();

    // console.log(ret);

    return ret;
  }

  showNotification(type, titulo?, msg?, tempo?) {
    tempo = tempo ? tempo : 8000;
    const color = Math.floor(Math.random() * 5 + 1);
    if (type === "default") {
      this.toastr.show(
        `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">${titulo}</span> <span data-notify="message">${msg}</span></div>`,
        "",
        {
          timeOut: tempo,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: "alert-title",
          positionClass: "toast-top-center",
          toastClass:
            "ngx-toastr alert alert-dismissible alert-default alert-notify"
        }
      );
    }
    if (type === "danger") {
      this.toastr.show(
        `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">${titulo}</span> <span data-notify="message">${msg}</span></div>`,
        "",
        {
          timeOut: tempo,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: "alert-title",
          positionClass: "toast-top-center",
          toastClass:
            "ngx-toastr alert alert-dismissible alert-danger alert-notify"
        }
      );
    }
    if (type === "success") {
      this.toastr.show(
        `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">${titulo}</span> <span data-notify="message">${msg}</span></div>`,
        "",
        {
          timeOut: tempo,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: "alert-title",
          positionClass: "toast-top-center",
          toastClass:
            "ngx-toastr alert alert-dismissible alert-success alert-notify"
        }
      );
    }
    if (type === "warning") {
      this.toastr.show(
        `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">${titulo}</span> <span data-notify="message">${msg}</span></div>`,
        "",
        {
          timeOut: tempo,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: "alert-title",
          positionClass: "toast-top-center",
          toastClass:
            "ngx-toastr alert alert-dismissible alert-warning alert-notify"
        }
      );
    }
    if (type === "info") {
      this.toastr.show(
        `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">${titulo}</span> <span data-notify="message">${msg}</span></div>`,
        "",
        {
          timeOut: tempo,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: "alert-title",
          positionClass: "toast-top-center",
          toastClass:
            "ngx-toastr alert alert-dismissible alert-info alert-notify"
        }
      );
    }
  }

  RetiraAcentos(str) {
    let com_acento = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';
    let sem_acento = 'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';
    let novastr = '';

    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < com_acento.length; a++) {
        if (str.substr(i, 1) === com_acento.substr(a, 1)) {
          novastr += sem_acento.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca === false) {
        novastr += str.substr(i, 1);
      }
    }
    return novastr;
  }

}
