import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "src/app/providers/global.service";

declare var window;

var misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
//Menu Items
// export const ROUTES: RouteInfo[] = [
export const ROUTES: RouteInfo[] = [
  // {
  //   path: "/dashboards",
  //   title: "Dashboards",
  //   type: "sub",
  //   icontype: "ni-shop text-primary",
  //   isCollapsed: true,
  //   children: [
  //     { path: "dashboard", title: "Dashboard", type: "link" },
  //     { path: "alternative", title: "Alternative", type: "link" }
  //   ]
  // },
  {
    path: "/dashboard",
    title: "Painel de bonificação",
    type: "link",
    icontype: " bi bi-currency-dollar text-primary"
    // icontype: "ni-laptop text-primary"
  },
  // {
  //   path: "/bonificar-receber",//path: "/bonificacao/identificacao",
  //   title: "Bonificação",
  //   type: "link",
  //   icontype: " bi bi-currency-dollar text-primary"
  // },
  {
    path: "/recargas",
    title: "Recargas",
    type: "link",
    icontype: "ni-shop text-primary"
  },
  {
    path: "/promocoes",
    title: "Promoções",
    type: "link",
    icontype: "bi bi-percent text-primary"
  },
  {
    path: "/publicidade",
    title: "Planos de Engajamento",
    type: "link",
    icontype: "bi bi-boxes text-primary"
  },
  // {
  //   path: "/extrato",//path: "/bonificacao/identificacao",
  //   title: "Extrato",
  //   type: "link",
  //   icontype: " bi bi-card-list text-primary"
  // },
  {
    path: "/",
    title: "Relatórios",
    type: "sub",
    icontype: "bi bi-card-list text-primary",
    collapse: "components",
    isCollapsed: true,
    children: [
      { path: "extrato", title: "Extrato Geral", type: "link" },
      {
        path: "/relatorioqr",
        isCollapsed: true,
        title: "QR Code para recebimentos",
        type: "sub",
        collapse: "relatorioqr",
        children: [
          { title: "Modelo 1", path: "modelo1", type: "link" },
          { title: "Modelo 2", path: "modelo2", type: "link" },
          { title: "Modelo 3", path: "modelo3", type: "link" },
          { title: "Modelo 4", path: "modelo4", type: "link" },
          { title: "Modelo 5", path: "modelo5", type: "link" },
          { title: "Modelo 6", path: "modelo6", type: "link" }
        ]
      }
    ]
  },
  {
    path: "/examples",
    title: "Examples",
    type: "sub",
    icontype: "ni-ungroup text-orange",
    collapse: "examples",
    isCollapsed: true,
    children: [
      { path: "pricing", title: "Pricing", type: "link" },
      { path: "login", title: "Login", type: "link" },
      { path: "register", title: "Register", type: "link" },
      { path: "lock", title: "Lock", type: "link" },
      { path: "timeline", title: "Timeline", type: "link" },
      { path: "profile", title: "Profile", type: "link" }
    ]
  },
  {
    path: "/components",
    title: "Components",
    type: "sub",
    icontype: "ni-ui-04 text-info",
    collapse: "components",
    isCollapsed: true,
    children: [
      { path: "buttons", title: "Buttons", type: "link" },
      { path: "cards", title: "Cards", type: "link" },
      { path: "grid", title: "Grid", type: "link" },
      { path: "notifications", title: "Notifications", type: "link" },
      { path: "icons", title: "Icons", type: "link" },
      { path: "typography", title: "Typography", type: "link" },
      {
        path: "multilevel",
        isCollapsed: true,
        title: "Multilevel",
        type: "sub",
        collapse: "multilevel",
        children: [
          { title: "Third level menu" },
          { title: "Just another link" },
          { title: "One last link" }
        ]
      }
    ]
  },
  {
    path: "/forms",
    title: "Forms",
    type: "sub",
    icontype: "ni-single-copy-04 text-pink",
    collapse: "forms",
    isCollapsed: true,
    children: [
      { path: "elements", title: "Elements", type: "link" },
      { path: "components", title: "Components", type: "link" },
      { path: "validation", title: "Validation", type: "link" }
    ]
  },
  {
    path: "/tables",
    title: "Tables",
    type: "sub",
    icontype: "ni-align-left-2 text-default",
    collapse: "tables",
    isCollapsed: true,
    children: [
      { path: "tables", title: "Tables", type: "link" },
      { path: "sortable", title: "Sortable", type: "link" },
      { path: "ngx-datatable", title: "Ngx Datatable", type: "link" }
    ]
  },
  {
    path: "/maps",
    title: "Maps",
    type: "sub",
    icontype: "ni-map-big text-primary",
    collapse: "maps",
    isCollapsed: true,
    children: [
      { path: "google", title: "Google Maps", type: "link" },
      { path: "vector", title: "Vector Map", type: "link" }
    ]
  },
  {
    path: "/widgets",
    title: "Widgets",
    type: "link",
    icontype: "ni-archive-2 text-green"
  },
  {
    path: "/charts",
    title: "Charts",
    type: "link",
    icontype: "ni-chart-pie-35 text-info"
  },
  {
    path: "/calendar",
    title: "Calendar",
    type: "link",
    icontype: "ni-calendar-grid-58 text-red"
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;

  constructor(
    private router: Router, public gs: GlobalService
  ) {

  }

  rotas = [];
  criaMenu() {
    ROUTES.splice(0, ROUTES.length);

   
    if (this.gs.souFranqueado() || this.gs.souExecutivo() || this.gs.souEstabelecimento()) {
      ROUTES.push({
        path: "/dashboard",
        title: "Painel de bonificação",
        type: "link",
        icontype: "bi bi-currency-dollar text-primary"
      });
    } else {
      ROUTES.push({
        path: "/dashboard",
        title: "Início",
        type: "link",
        icontype: " bi bi-currency-dollar text-primary"
      });
    }


    ROUTES.push({
      path: "/",
      title: "Configurações",
      type: "sub",
      icontype: "bi bi-gear text-primary",
      collapse: "components",
      isCollapsed: true,
      children: [
        { path: "meu-perfil", title: "Meu Perfil", type: "link" },
        { path: "caixas", title: "Caixas / Usuários", type: "link" }
      ]
    });

    ROUTES.push({
      path: "window.app.mSaldo()",
      title: "Adicionar Saldo",
      type: "function_addsaldo",
      icontype: "bi bi-currency-dollar text-primary"
    });

    if (!this.gs.usuario.usuario?.ocultarPainelBonificacao) {//(!this.gs.souFuncionario()) {//(this.gs.souFranqueado() || this.gs.souExecutivo() || this.gs.souEstabelecimento() || this.gs.souUsuarioComum()) {
      ROUTES.push({
        path: "/recargas",
        title: "Recargas",
        type: "link",
        icontype: "ni-shop text-primary"
      });
    }

    if (this.gs.souFranqueado() || this.gs.souExecutivo() || this.gs.souEstabelecimento()) {
      ROUTES.push({
        path: "/promocoes",
        title: "Cashback",
        type: "link",
        icontype: "bi bi-percent text-primary"
      });
    }

    ROUTES.push({
      path: "window.app.mIndicacao()",
      title: "Link de Indicação",
      type: "function_indicacao",
      icontype: "bi bi-person-plus text-primary"
    });


    if (this.gs.souFranqueado() || this.gs.souEstabelecimento() || this.gs.souExecutivo()) {
      ROUTES.push({
        path: "/publicidade",
        title: "Interação com o Cliente",
        type: "link",
        icontype: "bi bi-boxes text-primary"
      });
    }

    ROUTES.push({
      path: "/minha-rede",
      title: "Minha Rede",
      type: "link",
      icontype: "bi bi-bezier text-primary"
    });

    if (this.gs.souFranqueado() || this.gs.souExecutivo() || this.gs.souEstabelecimento()) {
      ROUTES.push({
        path: "/",
        title: "Relatórios",
        type: "sub",
        icontype: "bi bi-card-list text-primary",
        collapse: "components",
        isCollapsed: true,
        children: [
          { path: "extrato", title: "Extrato Geral", type: "link" },
          { path: "extrato-completo", title: "Extrato Completo", type: "link" },
          {
            path: "/relatorioqr",
            isCollapsed: true,
            title: "QR Code para recebimentos",
            type: "sub",
            collapse: "relatorioqr",
            children: [
              { title: "Modelo 1", path: "modelo1", type: "link" },
              { title: "Modelo 2", path: "modelo2", type: "link" },
              { title: "Modelo 3", path: "modelo3", type: "link" },
              { title: "Modelo 4", path: "modelo4", type: "link" },
              { title: "Modelo 5", path: "modelo5", type: "link" },
              { title: "Modelo 6", path: "modelo6", type: "link" }
            ]
          },
          {
            path: "/relatorio",
            isCollapsed: true,
            title: "Recorrência de Clientes",
            type: "sub",
            collapse: "relatorio",
            children: [
              { title: "Simplificado", path: "recorrencia-simplificada", type: "link" },
              { title: "Detalhado", path: "recorrencia-detalhada", type: "link" }
            ]
          }
        ]
      });
    } else {
      ROUTES.push({
        path: "/",
        title: "Relatórios",
        type: "sub",
        icontype: "bi bi-card-list text-primary",
        collapse: "components",
        isCollapsed: true,
        children: [
          { path: "extrato", title: "Extrato Geral", type: "link" }
        ]
      });
    }

    if (this.gs.souFranqueado() || this.gs.souAdministrador()) { //if (this.gs.souFranqueado() || this.gs.souEstabelecimento() || this.gs.souAdministrador()) {
      ROUTES.push({
        path: "/sorteios",
        title: "Sorteio Oficial",
        type: "link",
        icontype: "bi bi-dice-6 text-primary"
      });
    }

    if (this.gs.souAdministrador()) {
      ROUTES.push({
        path: "/seguranca",
        title: "Segurança",
        type: "sub",
        icontype: "bi bi-shield-check text-primary",
        collapse: "components",
        isCollapsed: true,
        children: [
          { path: "lista-pix", title: "Lista Pix Bloqueados", type: "link" },
          { path: "controle-usuario", title: "Controle de Usuários", type: "link" },
          { path: "usuarios-documentos", title: "Usuários e Documentos", type: "link" },
        ]
      });

      // ROUTES.push({
      //   path: "/area-de-membros",
      //   title: "Área de membros",
      //   type: "sub",
      //   icontype: "bi bi-bounding-box text-primary",
      //   collapse: "components",
      //   isCollapsed: true,
      //   children: [
      //     { path: "produtos", title: "Lista Produtos", type: "link" },
      //     { path: "controle-usuario", title: "Controle de Usuários", type: "link" },
      //     { path: "usuarios-documentos", title: "Usuários e Documentos", type: "link" },
      //   ]
      // });

      ROUTES.push({
        path: "/area-de-membros/produtos",
        title: "Área de membros",
        type: "link",
        icontype: "bi bi-bounding-box text-primary"
      });
    }

    if (this.gs.souFranqueado() || this.gs.souAdministrador() || this.gs.souEstabelecimento()) {
      ROUTES.push({
        path: "/campanha-de-marketing",
        title: "Campanha de Marketing",
        type: "link",
        icontype: "bi bi-envelope-check text-primary"
      });
    }

  }

  ngOnInit() {
    this.criaMenu();
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });
  }
  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName(
      "sidenav-toggler"
    )[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }

  alerta(p) {
    if (p === 'mIndicacao') {
      window.app.mIndicacao();
    }else if(p==='mSaldo'){
      window.app.mSaldo();
    }
  }
}
