import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { PresentationComponent } from './pages/presentation/presentation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CadastroComponent } from './cadastro/cadastro.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',//'presentation',
    pathMatch: 'full',
    data: { animation: 'LoginPage' }
  },
  {
    path: 'presentation',
    component: PresentationComponent,
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboards',
        loadChildren: './pages/dashboards/dashboards.module#DashboardsModule'
      },
      {
        path: 'components',
        loadChildren: './pages/components/components.module#ComponentsModule'
      },
      {
        path: 'forms',
        loadChildren: './pages/forms/forms.module#FormsModules'
      },
      {
        path: 'tables',
        loadChildren: './pages/tables/tables.module#TablesModule'
      },
      {
        path: 'maps',
        loadChildren: './pages/maps/maps.module#MapsModule'
      },
      {
        path: 'widgets',
        loadChildren: './pages/widgets/widgets.module#WidgetsModule'
      },
      {
        path: 'charts',
        loadChildren: './pages/charts/charts.module#ChartsModule'
      },
      {
        path: 'calendar',
        loadChildren: './pages/calendar/calendar.module#CalendarModule'
      },
      {
        path: 'examples',
        loadChildren: './pages/examples/examples.module#ExamplesModule'
      },
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        data: { animation: 'DashboardPage' }
      },
      {
        path: 'meu-perfil',
        loadChildren: './meu-perfil/meu-perfil/meu-perfil.module#MeuPerfilModule',
        data: { animation: 'MeuPerfilPage' }
      },
      {
        path: 'meu-perfil/senha',
        loadChildren: './meu-perfil/senha/senha.module#SenhaModule',
        data: { animation: 'SenhaPage' }
      },
      {
        path: 'meu-perfil/celular',
        loadChildren: './meu-perfil/celular/celular.module#CelularModule',
        data: { animation: 'CelularPage' }
      },
      {
        path: 'meu-perfil/email',
        loadChildren: './meu-perfil/email/email.module#EmailModule',
        data: { animation: 'EmailPage' }
      },
      {
        path: 'recargas',
        loadChildren: './recargas/recargas/recargas.module#RecargasModule',
        data: { animation: 'RecargasPage' }
      },
      {
        path: 'recarga-comprovante',
        loadChildren: './recargas/comprovante/comprovante.module#ComprovanteModule',
        data: { animation: 'ComprovantePage' }
      },
      {
        path: 'bonificar-receber',
        loadChildren: './bonificar-receber/identificacao/identificacao.module#IdentificacaoModule',
        data: { animation: 'IdentificacaoPage' }
      },
      {
        path: 'bonificar-receber/operacao',
        loadChildren: './bonificar-receber/operacao/operacao.module#OperacaoModule',
        data: { animation: 'OperacaoPage' }
      },
      {
        path: 'bonificar-receber/finalizar',
        loadChildren: './bonificar-receber/finalizar/finalizar.module#FinalizarModule',
        data: { animation: 'FinalizarPage' }
      },
      {
        path: 'bonificar-receber/confirmacao',
        loadChildren: './bonificar-receber/confirmacao/confirmacao.module#ConfirmacaoModule',
        data: { animation: 'ConfirmacaoPage' }
      },
      {
        path: 'extrato',
        loadChildren: './extrato/lista/lista.module#ListaModule',
        data: { animation: 'ExtratoListaPage' }
      },
      {
        path: 'extrato-completo',
        loadChildren: './extrato/completo/completo.module#CompletoModule',
        data: { animation: 'ExtratoCompetoPage' }
      },
      {
        path: 'promocoes',
        loadChildren: './promocoes/lista/lista.module#ListaModule',
        data: { animation: 'PromocoesListaPage' }
      },
      {
        path: 'promocoes/adicionar',
        loadChildren: './promocoes/adicionar/adicionar.module#AdicionarModule',
        data: { animation: 'PromocoesAdicionarPage' }
      },
      {
        path: 'promocoes/editar',
        loadChildren: './promocoes/adicionar/adicionar.module#AdicionarModule',
        data: { animation: 'PromocoesEditarPage' }
      },
      {
        path: 'publicidade',
        loadChildren: './engajamento/engajamento.module#EngajamentoModule',
        data: { animation: 'EngajamentoPage' }
      },
      {
        path: 'relatorioqr/modelo1',
        loadChildren: './relatorioqr/modelo1/modelo1.module#Modelo1Module',
        data: { animation: 'QRModelo1Page' }
      },
      {
        path: 'relatorioqr/modelo2',
        loadChildren: './relatorioqr/modelo2/modelo2.module#Modelo2Module',
        data: { animation: 'QRModelo2Page' }
      },
      {
        path: 'relatorioqr/modelo3',
        loadChildren: './relatorioqr/modelo3/modelo3.module#Modelo3Module',
        data: { animation: 'QRModelo3Page' }
      },
      {
        path: 'relatorioqr/modelo4',
        loadChildren: './relatorioqr/modelo4/modelo4.module#Modelo4Module',
        data: { animation: 'QRModelo4Page' }
      },
      {
        path: 'relatorioqr/modelo5',
        loadChildren: './relatorioqr/modelo5/modelo5.module#Modelo5Module',
        data: { animation: 'QRModelo5Page' }
      },
      {
        path: 'relatorioqr/modelo6',
        loadChildren: './relatorioqr/modelo6/modelo6.module#Modelo6Module',
        data: { animation: 'QRModelo6Page' }
      },
      {
        path: 'minha-rede',
        loadChildren: './minha-rede/minha-rede.module#MinhaRedeModule',
        data: { animation: 'MinhaRedePage' }
      },
      {
        path: 'caixas',
        loadChildren: './caixas/lista/lista.module#ListaModule',
        data: { animation: 'ListaCaixaPage' }
      },
      {
        path: 'caixas/adicionar',
        loadChildren: './caixas/adicionar/adicionar.module#AdicionarModule',
        data: { animation: 'AdicionarCaixaPage' }
      },
      {
        path: 'caixas/editar',
        loadChildren: './caixas/adicionar/adicionar.module#AdicionarModule',
        data: { animation: 'EditarCaixaPage' }
      },
      {
        path: 'sorteios',
        loadChildren: './sorteios/lista/lista.module#ListaModule',
        data: { animation: 'ListaSorteiosPage' }
      },
      {
        path: 'sorteios/adicionar',
        loadChildren: './sorteios/adicionar/adicionar.module#AdicionarModule',
        data: { animation: 'AdicionarSorteioPage' }
      },
      {
        path: 'sorteios/editar',
        loadChildren: './sorteios/adicionar/adicionar.module#AdicionarModule',
        data: { animation: 'EditarSorteioPage' }
      },
      {
        path: 'seguranca/lista-pix',
        loadChildren: './seguranca/lista-pix/lista-pix.module#ListaPixModule',
        data: { animation: 'SegurancaListaPixPage' }
      },
      {
        path: 'seguranca/controle-usuario',
        loadChildren: './seguranca/controle-usuario/controle-usuario.module#ControleUsuarioModule',
        data: { animation: 'SegurancaControleUsuarioPage' }
      },
      {
        path: 'seguranca/usuarios-documentos',
        loadChildren: './seguranca/usuarios-documentos/usuarios-documentos.module#UsuariosDocumentosModule',
        data: { animation: 'SegurancaUsuariosDocumentosPage' }
      },
      {
        path: 'campanha-de-marketing',
        loadChildren: './campanha-de-marketing/campanha-de-marketing.module#CampanhaDeMarketingModule',
        data: { animation: 'ListaCampanhasDeMarketingPage' }
      },
      {
        path: 'relatorio/recorrencia-simplificada',
        loadChildren: './relatorio/relatorio-simplificado/relatorio-simplificado.module#RelatorioSimplificadoModule',
        data: { animation: 'RecorrenciaSimplificadaPage' }
      },
      {
        path: 'relatorio/recorrencia-detalhada',
        loadChildren: './relatorio/relatorio-detalhado/relatorio-detalhado.module#RelatorioDetalhadoModule',
        data: { animation: 'RecorrenciaDetalhadaPage' }
      },
      {
        path: 'area-de-membros/produtos',
        loadChildren: './area-de-membros/produtos/produtos.module#ProdutosModule',
        data: { animation: 'amProdutosPage' }
      },
      {
        path: 'area-de-membros/novo',
        loadChildren: './area-de-membros/novo-produto/novo-produto.module#NovoProdutoModule',
        data: { animation: 'amNovoProdutoPage' }
      },
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'examples',
        loadChildren:
          './layouts/auth-layout/auth-layout.module#AuthLayoutModule',
          data: { animation: 'ExamplePage' }
      },
      {
        path: '',
        loadChildren:
          './layouts/auth-layout/auth-layout.module#AuthLayoutModule',
          data: { animation: 'AllPage' }
      },
      {
        path: 'cadastro',
        loadChildren:
          './layouts/auth-layout/auth-layout.module#AuthLayoutModule',
          data: { animation: 'CadastroPage' }
      },
      {
        path: 'cadastro/sucesso',
        loadChildren:
          './layouts/auth-layout/auth-layout.module#AuthLayoutModule',
          data: { animation: 'CadastroSucessoPage' }
      },
      {
        path: 'termos',
        loadChildren:
          './layouts/auth-layout/auth-layout.module#AuthLayoutModule',
          data: { animation: 'TermosPage' }
      },
      {
        path: 'termos/cliente',
        loadChildren:
          './layouts/auth-layout/auth-layout.module#AuthLayoutModule',
          data: { animation: 'TermosClientePage' }
      },
      {
        path: 'termos/estabelecimento',
        loadChildren:
          './layouts/auth-layout/auth-layout.module#AuthLayoutModule',
          data: { animation: 'TermosEstabelecimentoPage' }
      },
      {
        path: 'termos/executivo',
        loadChildren:
          './layouts/auth-layout/auth-layout.module#AuthLayoutModule',
          data: { animation: 'TermosExecutivoPage' }
      },
      {
        path: 'esqueci-senha',
        loadChildren:
          './layouts/auth-layout/auth-layout.module#AuthLayoutModule',
          data: { animation: 'TermosExecutivoPage' }
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/dashboard',
    data: { animation: 'TodasPage' }
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
