import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { GlobalService } from '../providers/global.service';
import { Router } from '@angular/router';
import { DxoRemoteOperationsComponent } from 'devextreme-angular/ui/nested';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(
    public http: HttpClient, private gs: GlobalService, private router: Router
  ) { }

  fazLogin(objLogin: any): Observable<any | null> {
    const url = `/base_api/autenticacao`;

    const retorno = {} as any;

    return from(this.http
      // .post(url, objLogin, { headers: h, observe: 'response' })
      .post(url, objLogin, { observe: 'response' })
      .toPromise().then((v) => {
        retorno.status = 200;
        retorno.obj = v.body;
        retorno.msg = '';
        // console.log(v);
        return retorno;
      }).catch((ex) => {
        // console.log(ex);
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error;//.join('; ');
        } catch (err) { }
        if (retorno.status === 401) {
          retorno.msg = 'Login ou Senha inválido(s)';
        }
        return retorno;
      })
    );
  }

  fazLoginComToken(objLogin: any): Observable<any | null> {
    const url = `/base_api/autenticacao/login`;

    const retorno = {} as any;

    return from(this.http
      // .post(url, objLogin, { headers: h, observe: 'response' })
      .post(url, objLogin, { observe: 'response' })
      .toPromise().then((v) => {
        // console.log(v);
        retorno.status = 200;
        retorno.obj = v.body;
        retorno.msg = '';
        // console.log(v);
        return retorno;
      }).catch((ex) => {
        // console.log(ex);
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        if (retorno.status === 401) {
          retorno.msg = 'Login ou Senha inválido(s)';
        }
        return retorno;
      })
    );
  }

  cadastrar(cad: any): Observable<any | null> {
    const url = `/base_api/usuarios/novo`;

    const retorno = {} as any;

    return from(this.http
      // .post(url, objLogin, { headers: h, observe: 'response' })
      .post(url, cad, { observe: 'response' })
      .toPromise().then((v) => {
        retorno.status = 200;
        retorno.obj = v.body;
        retorno.msg = '';
        // console.log(v);
        return retorno;
      }).catch((ex) => {
        // console.log(ex);
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  meuIndicador(o): Observable<any | null> {
    const url = `/base_api/usuarios/indicador`;

    const retorno = {} as any;

    return from(this.http
      .post(url, o, { observe: 'response' })
      .toPromise().then((v) => {
        retorno.status = 200;
        retorno.obj = v.body;
        retorno.msg = '';
        // console.log(v);
        return retorno;
      }).catch((ex) => {
        // console.log(ex);
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  meusDados(): Observable<any | null> {
    const url = `/base_auth_api/usuarios`;

    const retorno = {} as any;

    return from(this.http
      .get(url, { observe: 'response' })
      .toPromise().then((v) => {
        retorno.status = 200;
        retorno.obj = v.body;
        retorno.msg = '';
        // console.log(v);
        return retorno;
      }).catch((ex) => {
        // console.log(ex);
        if (ex.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  dadosEstabelecimento(): Observable<any | null> {
    const url = `/base_auth_api/usuarios/estabelecimento`;

    const retorno = {} as any;

    return from(this.http
      .get(url, { observe: 'response' })
      .toPromise().then((v) => {
        retorno.status = 200;
        retorno.obj = v.body;
        retorno.msg = '';
        // console.log(v);
        return retorno;
      }).catch((ex) => {
        console.log(ex);
        if (ex.status === 401) {
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  salvaDadosEstabelecimento(est): Observable<any | null> {
    const url = `/base_auth_api/usuarios/estabelecimento`;

    const retorno = {} as any;

    return from(this.http
      .put(url, est, { observe: 'response' })
      .toPromise().then((v) => {
        retorno.status = 200;
        retorno.obj = v.body;
        retorno.msg = '';
        // console.log(v);
        return retorno;
      }).catch((ex) => {
        // console.log(ex);
        if (ex.status === 401) {
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  informacoes(): Observable<any | null> {
    const url = `/base_auth_api/usuarios/informacoes`;

    const retorno = {} as any;

    return from(this.http
      .get(url, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(d);
        retorno.status = 200;
        try {
          retorno.obj = d.body;
        } catch (ex) { }
        // console.log(retorno.obj);
        this.gs.usuario.usuario.saldo = retorno.obj;
        this.gs.gravaDados('dadosUsuario', this.gs.usuario);
        return retorno;
      })
      .catch((er) => {
        // console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        retorno.obj = null;
        return retorno;
      })
    );
  }

  salvarPerfil(objPerfil: any): Observable<any | null> {
    const url = `/base_auth_api/usuarios`;

    const retorno = {} as any;

    return from(this.http
      // .post(url, objLogin, { headers: h, observe: 'response' })
      .put(url, objPerfil, { observe: 'response' })
      .toPromise().then((v) => {
        retorno.status = 200;
        retorno.obj = v.body;
        retorno.msg = '';
        // console.log(v);
        return retorno;
      }).catch((ex) => {
        // console.log(ex);
        if (ex.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  deslogar(): Observable<any | null> {
    const url = `/base_auth_api/autenticacao/desloga`;

    const retorno = {} as any;

    return from(this.http
      // .post(url, objLogin, { headers: h, observe: 'response' })
      .put(url, {}, { observe: 'response' })
      .toPromise().then((v) => {
        this.gs.apagaDados('dadosUsuario');
        retorno.status = 200;
        retorno.obj = v.body;
        retorno.msg = '';
        // console  .log(v);
        return retorno;
      }).catch((ex) => {
        // console.log(ex);
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  solicitaAutorizacaoCelular(): Observable<any | null> {
    const url = `/base_auth_api/autorizacao/sms`;
    const retorno = {} as any;

    return from(this.http
      .post(url, {}, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(d);
        retorno.status = 200;
        try {
          retorno.obj = d.body;
        } catch (ex) {
          try {
            retorno.obj = d.body;
          } catch (xx) { }
        }
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        // console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  validaAutorizacaoCelular(token, rec): Observable<any | null> {
    const url = `/base_auth_api/autorizacao/sms`;
    const retorno = {} as any;

    return from(this.http
      .put(`${url}/${token}`, { gr: rec }, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(d);
        retorno.status = 200;
        try {
          retorno.obj = d.body;
        } catch (ex) {
          retorno.obj = d.body;
        }
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        // console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  solicitaAutorizacaoEmail(): Observable<any | null> {
    const url = `/base_auth_api/autorizacao/email`;
    const retorno = {} as any;

    return from(this.http
      .post(url, {}, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(d);
        retorno.status = 200;
        try {
          retorno.obj = d.body;
        } catch (ex) {
          retorno.obj = d.body;
        }
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  validaAutorizacaoEmail(token, rec): Observable<any | null> {
    const url = `/base_auth_api/autorizacao/email`;
    const retorno = {} as any;

    return from(this.http
      .put(`${url}/${token}`, { gr: rec }, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(d);
        retorno.status = 200;
        try {
          retorno.obj = d.body;
        } catch (ex) {
          retorno.obj = null;
        }
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        // console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  solicitarTrocaSenha(): Observable<any | null> {
    const url = `/base_auth_api/usuarios/senha/token`;
    const retorno = {} as any;

    return from(this.http
      .post(url, {}, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(d);
        retorno.status = 200;
        try {
          retorno.obj = d.body;
        } catch (ex) {
          retorno.obj = d.body;
        }
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        // console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  alterarSenha(tSenha): Observable<any | null> {
    const url = `/base_auth_api/usuarios/senha`;
    const retorno = {} as any;

    return from(this.http
      .put(url, tSenha, { observe: 'response' })
      .toPromise().then((d) => {
        //console.log(d);
        retorno.status = 200;
        retorno.obj = d.body;
        this.gs.usuario.usuario.token = retorno.obj.token;
        this.gs.gravaDados('dadosUsuario', this.gs.usuario)
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        //console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  solicitarTrocaSenhaNA(c): Observable<any | null> {
    const url = `/base_api/usuarios/esqueciminhasenha/token`;
    const retorno = {} as any;

    return from(this.http
      .post(url, c, { observe: 'response' })
      .toPromise().then((d) => {
        console.log(d);
        retorno.status = 200;
        try {
          retorno.obj = d.body;
        } catch (ex) {
          retorno.obj = d.body;
        }
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          // this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  alterarSenhaNA(tSenha): Observable<any | null> {
    const url = `/base_api/usuarios/esqueciminhasenha/senha`;
    const retorno = {} as any;

    return from(this.http
      .put(url, tSenha, { observe: 'response' })
      .toPromise().then((d) => {
        //console.log(d);
        retorno.status = 200;
        retorno.obj = d.body;
        this.gs.usuario.usuario.token = retorno.obj.token;
        this.gs.gravaDados('dadosUsuario', this.gs.usuario)
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        //console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          // this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  reqTokenEmailCelular(tipo, contato): Observable<any | null> {
    const url = `/base_auth_api/usuarios/geratokenautenticacao`;
    const retorno = {} as any;

    return from(this.http
      .post(url, { tipoToken: tipo, novoContato: contato }, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(d);
        retorno.status = 200;
        try {
          retorno.obj = d.body;
        } catch (ex) {
          retorno.obj = null;
        }
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        // console.log(er);
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  alterarEmail(_token, _email, rec): Observable<any | null> {
    const url = `/base_auth_api/usuarios/email`;
    const retorno = {} as any;

    return from(this.http
      .put(url, { token: _token, email: _email, gr: rec }, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(d);
        retorno.status = 200;
        try {
          retorno.obj = d.body;
        } catch (ex) {
          retorno.obj = null;
        }
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        // console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  alterarCelular(_token, _celular, rec): Observable<any | null> {
    const url = `/base_auth_api/usuarios/celular`;
    const retorno = {} as any;

    return from(this.http
      .put(url, { token: _token, celular: _celular, gr: rec }, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(d);
        retorno.status = 200;
        try {
          retorno.obj = d.body;
        } catch (ex) {
          retorno.obj = null;
        }
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        // console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  alterarLocalizacao(loc): Observable<any | null> {
    const url = `/base_auth_api/usuarios/localizacao`;
    const retorno = {} as any;

    return from(this.http
      .put(url, loc, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(d);
        retorno.status = 200;
        try {
          retorno.obj = d.body;
        } catch (ex) {
          retorno.obj = null;
        }
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        // console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  ultimaTransacao(): Observable<any | null> {
    const url = `/base_auth_api/transacao/dataultimatransacao`;

    const retorno = {} as any;

    return from(this.http
      // .get(`${ambienteNivel.URLApi}/transacao/dataultimatransacao`, {}, h)
      .get(url, { observe: 'response' })
      .toPromise().then((d) => {
        retorno.status = 200;
        retorno.obj = d.body;//JSON.parse(d.data);
        if (retorno.obj.dataUltimaTransacao) {
          this.gs.dataUltimaTransacao = retorno.obj.dataUtimaTransacao;
        }
        return retorno;
      })
      .catch((er) => {
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        retorno.obj = null;
        return retorno;
      })
    );
  }

  extratoUser(inicio: string, fim: string): Observable<any | null> {
    const url = `/base_auth_api/usuarios/extrato?DataInicio=${inicio}T00:00:00&DataFim=${fim}T23:59:59`;
    const retorno = {} as any;

    // console.log(url);

    return from(this.http
      //.get(`${ambienteNivel.URLApi}/usuarios/extrato?DataInicio=${inicio}T00:00:00&DataFim=${fim}T23:59:59`, {}, h)
      .get(url, { observe: 'response' })
      .toPromise().then((d) => {
        retorno.status = 200;
        retorno.obj = d.body;//JSON.parse(d.data);
        return retorno;
      })
      .catch((er) => {
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        retorno.obj = null;
        return retorno;
      })
    );
  }

  extratoCompleto(inicio: string, fim: string, operacao: string, transacao: string, caixa: string): Observable<any | null> {
    const url = `/base_auth_api/transacao/extratocompleto?DataInicial=${inicio}T00:00:00&DataFinal=${fim}T23:59:59&tipooperacao=${transacao}&tipotransacao=${operacao}&caixa=${caixa}`;
    const retorno = {} as any;

    // console.log(url);

    return from(this.http
      //.get(`${ambienteNivel.URLApi}/usuarios/extrato?DataInicio=${inicio}T00:00:00&DataFim=${fim}T23:59:59`, {}, h)
      .get(url, { observe: 'response' })
      .toPromise().then((d) => {
        retorno.status = 200;
        retorno.obj = d.body;//JSON.parse(d.data);
        return retorno;
      })
      .catch((er) => {
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        retorno.obj = null;
        return retorno;
      })
    );
  }

  comprovanteTransacao(id, commissionId?): Observable<any | null> {
    const url = `/base_auth_api/transacao/comprovante?TransactionId=${id}&CommissionId=${commissionId}`;
    const retorno = {} as any;

    if (!commissionId) {
      commissionId = 0;
    }

    return from(this.http
      .get(url, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(JSON.parse(d.data));
        retorno.status = 200;
        retorno.obj = d.body;//JSON.parse(d.data);
        return retorno;
      })
      .catch((er) => {
        // console.log(er);
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        retorno.obj = null;
        return retorno;
      })
    );
  }

  salvaImagem(img): Observable<any | null> {
    const url = `/base_auth_api/usuarios/imagens`;
    const retorno = {} as any;

    return from(this.http
      .put(url, img, { observe: 'response' })
      .toPromise().then((d) => {
        retorno.status = 200;
        retorno.obj = d.body;
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        retorno.obj = null;
        return retorno;
      })
    );
  }

  indicacaoDados(): Observable<any | null> {
    const url = `/base_auth_api/usuarios/indicacao/dados`;
    const retorno = {} as any;

    return from(this.http
      .get(url, { observe: 'response' })
      .toPromise().then((d) => {
        retorno.status = 200;
        retorno.obj = d.body; //JSON.parse(d.data);
        // this.gs.config.dadosUsuario.usuario = retorno.obj;
        // console.log(this.gs.config.dadosUsuario.usuario);
        // this.gs.gravaDadosAPP();
        return retorno;
      })
      .catch((er) => {
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        retorno.obj = null;
        return retorno;
      })
    );
  }

  listaRamosAtividade(): Observable<any | null> {
    const url = `/base_auth_api/parceria/buscaramosatividade`;

    const retorno = {} as any;

    return from(this.http
      .get(url, { observe: 'response' })
      .toPromise().then((d) => {
        retorno.status = 200;
        retorno.obj = d.body;
        return retorno;
      })
      .catch((er) => {
        // console.log(er);
        if (er.status === 401) {
          this.router.navigate(['/login']);
        }
        retorno.status = er.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  gravarPushToken(token): Observable<any | null> {
    const url = `/base_auth_api/usuarios/adicionarpushtoken`;
    const retorno = {} as any;

    return from(this.http
      .put(url, {pushToken: token}, { observe: 'response' })
      .toPromise().then((d) => {
        retorno.status = 200;
        retorno.obj = d.body;
        return retorno;
      })
      .catch((er) => {
        // console.log('erro');
        if (er.status === 401) {
          this.gs.apagaDados('dadosUsuario');
          this.router.navigate(['/login?forca=1'], { queryParams: { forca: 1 } });
        }
        retorno.status = er.status;
        retorno.msg = '';
        try {
          retorno.msg = er.error.join('; ');
        } catch (err) { }
        retorno.obj = null;
        return retorno;
      })
    );
  }

  verificaWebTokenGravado() {
    ///webToken = '';
    //webTokenGravado = false;
    if (this.gs.webToken !== '' && this.gs.webTokenGravado === false) {
      this.gravarPushToken(this.gs.webToken).subscribe((r) => {
        if (r.status === 200) {
          this.gs.webTokenGravado = true;
        }
      });
    }
  }


}
