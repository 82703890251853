import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { GlobalService } from '../providers/global.service';
import { PIX } from '../models/pagamento';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PagamentoService {

  constructor(
    public http: HttpClient, private gs: GlobalService, private router: Router
  ) { }

  geraChavePix(pix: PIX): Observable<any | null> {
    const url = `/base_auth_api/pix`;

    const retorno = {} as any;
    return from(this.http
      .post(url, pix, { observe: 'response' })
      .toPromise().then((v) => {
        // console.log(JSON.parse(d.data));
        retorno.status = 200;
        // retorno.obj = JSON.parse(d.data);
        retorno.obj = v.body;
        retorno.msg = '';
        return retorno;
      })
      .catch((ex) => {
        // console.log(ex);
        if (ex.status === 401) {
          this.router.navigate(['/login']);
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  consultaChavePix(chv: string, emv: boolean, cel: boolean): Observable<any | null> {
    const url = `/base_auth_api/pix/consultapixresgate`;

    const retorno = {} as any;

    return from(this.http
      .post(url, { chave: chv, chaveEmv: emv, valor: 0, celular: cel }, { observe: 'response' })
      .toPromise().then((v) => {
        // console.log(JSON.parse(d.data));
        retorno.status = 200;
        // retorno.obj = JSON.parse(d.data);
        retorno.obj = v.body;
        retorno.msg = '';
        return retorno;
      })
      .catch((ex) => {
        // console.log(ex);
        if (ex.status === 401) {
          this.router.navigate(['/login']);
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  executaPix(pix): Observable<any | null> {
    const url = `/base_auth_api/pix/pagamentopixresgate`;

    const retorno = {} as any;

    return from(this.http
      .post(url, pix, { observe: 'response' })
      .toPromise().then((v) => {
        // console.log(JSON.parse(d.data));
        retorno.status = 200;
        // retorno.obj = JSON.parse(d.data);
        retorno.obj = v.body;
        retorno.msg = '';
        return retorno;
      })
      .catch((ex) => {
        // console.log(er);
        if (ex.status === 401) {
          this.router.navigate(['/login']);
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  buscaUsuario(doc: string): Observable<any | null> {
    const url = `/base_auth_api/usuarios/transferencia/${doc}`;

    const retorno = {} as any;

    return from(this.http
      .get(url, { observe: 'response' })
      .toPromise().then((d) => {
        retorno.status = 200;
        retorno.obj = d.body;
        return retorno;
      })
      .catch((ex) => {
        if (ex.status === 401) {
          this.router.navigate(['/login']);
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  transacao(pg): Observable<any | null> {
    const url = `/base_auth_api/transacao`;

    const retorno = {} as any;

    return from(this.http
      .put(url, pg, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(d.body);
        retorno.status = 200;
        retorno.obj = d.body;
        if(retorno.obj.sucesso){
          this.gs.usuario.usuario.saldo.saldo = parseFloat(retorno.obj.saldoAtual.replace(/\./gi,'').replace(',','.'));
          this.gs.gravaDados('dadosUsuario', this.gs.usuario);
        }
        return retorno;
      })
      .catch((ex) => {
        // console.log(ex);
        if (ex.status === 401) {
          this.router.navigate(['/login']);
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  pagamento(pg): Observable<any | null> {
    const url = `/base_auth_api/transacao/pagamento`;

    const retorno = {} as any;

    return from(this.http
      .put(url, pg, { observe: 'response' })
      .toPromise().then((d) => {
        // console.log(JSON.parse(d.data));
        retorno.status = 200;
        retorno.obj = d.body;
        //console.log(retorno);
        if(retorno.obj.sucesso){
          this.gs.usuario.usuario.saldo.saldo = parseFloat(retorno.obj.saldoAtual.replace(/\./gi,'').replace(',','.'));
          this.gs.gravaDados('dadosUsuario', this.gs.usuario);
        }
        return retorno;
      })
      .catch((ex) => {
        // console.log(ex);
        if (ex.status === 401) {
          this.router.navigate(['/login']);
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  geraChavePixPagamento(pix: any): Observable<any | null> {
    const url = `/base_auth_api/pix/pagamentoparaempresaporpix`;

    const retorno = {} as any;
    return from(this.http
      .post(url, pix, { observe: 'response' })
      .toPromise().then((v) => {
        // console.log(JSON.parse(d.data));
        retorno.status = 200;
        // retorno.obj = JSON.parse(d.data);
        retorno.obj = v.body;
        retorno.msg = '';
        return retorno;
      })
      .catch((ex) => {
        // console.log(ex);
        if (ex.status === 401) {
          this.router.navigate(['/login']);
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

  pagarEmpresaPix(pix): Observable<any | null> {
    const url = `/base_auth_api/pix/pagamentoparaempresaporpix`;

    const retorno = {} as any;
    return from(this.http
      .post(url, pix, { observe: 'response' })
      .toPromise().then((v) => {
        // console.log(JSON.parse(d.data));
        retorno.status = 200;
        // retorno.obj = JSON.parse(d.data);
        retorno.obj = v.body;
        retorno.msg = '';
        return retorno;
      })
      .catch((ex) => {
        // console.log(ex);
        if (ex.status === 401) {
          this.router.navigate(['/login']);
        }
        retorno.status = ex.status;
        retorno.obj = null;
        retorno.msg = '';
        try {
          retorno.msg = ex.error.join('; ');
        } catch (err) { }
        return retorno;
      })
    );
  }

}
