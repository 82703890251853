import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { AsyncPipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { TagInputModule } from 'ngx-chips';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { PresentationModule } from './pages/presentation/presentation.module';

import { BrowserModule } from '@angular/platform-browser';
import { ComponentsModule } from './components/components.module';

import { AppRoutingModule } from './app-routing.module';
import { InterceptorModule } from './providers/interceptors.module';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ClipboardModule } from 'ngx-clipboard';

import { CookieService } from 'ngx-cookie-service';

import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { environment, firebaseConfig } from '../environments/environment';
import { MessagingService } from './services/push.service';
import { ValorPixModule } from './components/valor-pix/valor-pix.module';

import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';


registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ComponentsModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    PresentationModule,
    BrowserModule,
    AppRoutingModule,
    InterceptorModule,
    ModalModule,
    RecaptchaV3Module,
    ClipboardModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireMessagingModule,
    ValorPixModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    // { provide: 'BASE_API_URL', useValue: environment.apiUrl, useClass: Interceptor },
    // { provide: InterceptorsService, useValue: environment.apiUrl, useClass: InterceptorsService },
    BsModalService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.siteKey,//'6LcKF7QbAAAAADx-XBAlavjwCWN8mz7JuITXhmMG',
        // siteKey: '6LeLaLQbAAAAAD0NwoirwMeAqAI_o7pRrWjpd0Ed' //homolog
      } as RecaptchaSettings,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.rV3siteKey},//'6LeoaGUhAAAAAFuwFSsPGuiMVxQLuM3cBaV2LfjZ' }, //6Lc_3L8eAAAAAHNsMR-ejMtLTE3dED54ican0djT //6LeoaGUhAAAAAFuwFSsPGuiMVxQLuM3cBaV2LfjZ
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    CookieService,
    MessagingService,
    AsyncPipe,

  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
