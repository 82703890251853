<router-outlet></router-outlet>
<ng-template #modalIndicacao>
    <div class=" modal-header">
        <h6 class=" modal-title" id="modal-title-notification">
            <!-- Seu Link de Indicação de Clientes -->
            Este é o seu link de indicação.
        </h6>

        <button aria-label="Close" class=" close" data-dismiss="modal" type="button" (click)="formIndicacao.hide()">
            <span aria-hidden="true"> × </span>
        </button>
    </div>

    <div class=" modal-body">
        <!-- <h4>Compartilhe este link e consiga vários clientes ligados a você.<br><br>
        Ele leva quem clicar diretamente para a tela de cadastro da Nível e vincula a você.<br><br>
        Ganhe 5% de tudo que seu amigo ganhar!<br><br></h4> -->
        <h4>
            Compartilhe este link e tenha muitos benefícios.<br>
            Ganhe 5% de todo casback que seus amigos ganharem.<br><br>
        </h4>

        <h3 class="btn-default">{{dadosIndicador.linkIndicacao}}</h3> <button type="button"
            class="btn btn-success btn-icon-only p-0 float-right" (click)="copiarLinkIndicacao();"
            [class]="gs.isMobile?'btn-sm':'btn-sm'"><span class="btn-inner--icon"><i class="bi bi-clipboard-check"
                    style="font-size:1.5em;"></i></span></button>
    </div>

    <div class=" modal-footer">
        <button class=" btn btn-white pull-right" type="button" (click)="formIndicacao.hide()">
            Fechar
        </button>
    </div>
</ng-template>

<ng-template #modalAddSaldo>
    <app-valor-pix>

    </app-valor-pix>
</ng-template>

<div style="width:100%;height:100%;position:fixed;top:0;left:0;background:#000000AA;z-index:1000000;" id="overlay-progresso" *ngIf="gs.barraUpload.show">
    <div class="c-barra-progresso" style="position:absolute;width:70%;top:calc(50% - 25px);left:15%;border:1px solid #CECECE;height:50px;background:#ffffff;">
      <div style="position:absolute;font-size:1em;color:#000000;font-weight:bolder;text-align:center;width:100%;top:50%;transform:translateY(-35%);">
        <span class="pct-envio">{{gs.barraUpload.pct}}%</span>
        <p class="nome-arquivo">{{gs.barraUpload.arquivo}}</p>
      </div>
      <div class="barra-progresso" style="background:#2dce89;color:#000000;font-weight:700;text-align:center;left:0;width:{{gs.barraUpload.pct}}%;height:100%;"></div>
    </div>
  </div>