// import { TabFocusDirective } from './../diretiva/tab-focus.directive';
import { NgModule, SkipSelf, Optional } from '@angular/core';
import { CpfCnpjDirective } from '../mascara/cpf-cnpj.directive';
import { TelefoneDirective } from '../mascara/telefone.directive';
import { DataDirective } from '../mascara/data.directive';
import { CepDirective } from '../mascara/cep.directive';
import { MoedaDirective } from '../mascara/moeda.directive';
import { PercentualDirective } from '../mascara/percentual.directive';
import { NumeroDirective } from '../mascara/numero.directive';



@NgModule({
  exports: [
    // TabFocusDirective,
    CpfCnpjDirective, TelefoneDirective, DataDirective, CepDirective, MoedaDirective, PercentualDirective, NumeroDirective],
  declarations: [
    CpfCnpjDirective, TelefoneDirective, DataDirective, CepDirective, MoedaDirective, PercentualDirective, NumeroDirective],
})
export class CompartilhadoModule {
  constructor(@Optional() @SkipSelf() parentModule?: CompartilhadoModule) {

  }
}
