<!-- <nav id="navbar-main" class="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-dark">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/dashboards/dashboard']">
      <img src="assets/img/brand/white.png" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse"
      aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse navbar-custom-collapse collapse" id="collapseBasic" [collapse]="isCollapsed">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/dashboards/dashboard']">
              <img src="assets/img/brand/blue.png" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse"
              aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation"
              (click)="isCollapsed = !isCollapsed">
              <span></span> <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/dashboards/dashboard']">
            <span class="nav-link-inner--text">Dashboard</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/examples/pricing']">
            <span class="nav-link-inner--text">Pricing</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/examples/login']">
            <span class="nav-link-inner--text">Login</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/examples/register']">
            <span class="nav-link-inner--text">Register</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/examples/lock']">
            <span class="nav-link-inner--text">Lock</span>
          </a>
        </li>
      </ul>
      <hr class="d-lg-none" />
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <a class="nav-link nav-link-icon" href="https://www.facebook.com/creativetim" target="_blank"
            data-toggle="tooltip" data-original-title="Like us on Facebook">
            <i class="fab fa-facebook-square"></i>
            <span class="nav-link-inner--text d-lg-none">Facebook</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-link-icon" href="https://www.instagram.com/creativetimofficial" target="_blank"
            data-toggle="tooltip" data-original-title="Follow us on Instagram">
            <i class="fab fa-instagram"></i>
            <span class="nav-link-inner--text d-lg-none">Instagram</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-link-icon" href="https://twitter.com/creativetim" target="_blank" data-toggle="tooltip"
            data-original-title="Follow us on Twitter">
            <i class="fab fa-twitter-square"></i>
            <span class="nav-link-inner--text d-lg-none">Twitter</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-link-icon" href="https://github.com/creativetimofficial" target="_blank"
            data-toggle="tooltip" data-original-title="Star us on Github">
            <i class="fab fa-github"></i>
            <span class="nav-link-inner--text d-lg-none">Github</span>
          </a>
        </li>
        <li class="nav-item d-none d-lg-block ml-lg-4">
          <a href="https://www.creative-tim.com/product/argon-dashboard-pro-angular?ref=adpa-auth-layout"
            target="_blank" class="btn btn-neutral btn-icon">
            <span class="btn-inner--icon">
              <i class="fas fa-shopping-cart mr-2"></i>
            </span>
            <span class="nav-link-inner--text">Purchase now</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav> -->
<router-outlet></router-outlet>
<footer class="py-5">
  <div class="container">
    <!-- <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6">
        <div class="copyright text-center text-xl-left text-muted">
          &copy; {{ test | date: "yyyy" }}
          <a href="https://www.creative-tim.com?ref=adpa-footer-admin-layout" class="font-weight-bold ml-1"
            target="_blank">Creative Tim</a>
        </div>
      </div>
      <div class="col-xl-6">
        <ul class="nav nav-footer justify-content-center justify-content-xl-end">
          <li class="nav-item">
            <a href="https://www.creative-tim.com?ref=adpa-footer-admin-layout" class="nav-link"
              target="_blank">Creative Tim</a>
          </li>
          <li class="nav-item">
            <a href="https://www.creative-tim.com/presentation?ref=adpa-footer-admin-layout" class="nav-link"
              target="_blank">About Us</a>
          </li>
          <li class="nav-item">
            <a href="http://blog.creative-tim.com?ref=adpa-footer-admin-layout" class="nav-link"
              target="_blank">Blog</a>
          </li>
          <li class="nav-item">
            <a href="https://www.creative-tim.com/license" class="nav-link" target="_blank">License</a>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="row align-items-center justify-content-center">
      <div class="col-xl-12">
        <div class="copyright text-center text-xl-center text-muted">
          Copyright © 2022 Nivel Cashback
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="animacao-loading" *ngIf="gs.animacao" style="z-index:100000 !important;">
  <img src="assets/img/brand/loading.svg">
</div>