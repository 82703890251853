import { animateChild, query, style, transition, trigger, group, animate } from "@angular/animations";

export const slideInAnimation =
    trigger('routeAnimations', [
        transition('HomePage <=> AboutPage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                })
            ]),
            query(':enter', [
                style({ left: '-100%' })
            ]),
            query(':leave', animateChild(), {optional: true}),
            group([
                query(':leave', [
                    animate('300ms ease-out', style({ left: '100%' }))
                ]),
                query(':enter', [
                    animate('300ms ease-out', style({ left: '0%' }))
                ]),
            ]),
        ]),
        transition('* <=> *', [
            // style({ position: 'relative' }),
            // query(':enter, :leave', [
            //     style({
            //         position: 'absolute',
            //         top: 0,
            //         left: 0,
            //         width: '100%'
            //     })
            // ]),
            // query(':enter', [
            //     style({ left: '-100%' })
            // ]),
            // query(':leave', animateChild()),
            // group([
            //     query(':leave', [
            //         animate('200ms ease-out', style({ left: '100%' }))
            //     ]),
            //     query(':enter', [
            //         animate('300ms ease-out', style({ left: '0%' }))
            //     ]),
            //     // query('@*', animateChild())
            // ]),
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                })
            ]),
            query(':enter', [
                style({ left: '100%', top:'200%' })//style({ left: '-100%' })
            ]),
            query(':leave', animateChild(), {optional: true}),
            group([
                query(':leave', [
                    animate('200ms ease-out', style({ left: '100%', top:'200%' }))//style({ left: '100%' }))
                ],{optional: true}),
                query(':enter', [
                    animate('500ms ease-out', style({ left: '0%', top:'0%' }))//style({ left: '0%' }))
                ],{optional: true}),
                // query('@*', animateChild())
            ]),
            
        ])
    ]);