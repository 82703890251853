import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[maskCPFCNPJ]',
  // host: {
  //   '(keyup)': 'mascara($event)'
  // }
})
export class CpfCnpjDirective {

  constructor(public element: ElementRef) { }

  @HostListener('keyup', ['$event']) OnKeyUp() {
    this.mascara(event);
  }

  mascara(e) {
    e.preventDefault();

    this.element.nativeElement.maxlength = 18;
    let mask: string;
    let tamanho: number;
    let formValue = e.target.value;
    if (formValue.length <= 14) {
      tamanho = 14;
      mask = '999.999.999-99';
      formValue = formValue.replace(/\D/gi, '');
      formValue = formValue.replace(/(\d{3})(\d)/gi, '$1.$2');
      formValue = formValue.replace(/(\d{3})(\d)/gi, '$1.$2');
      formValue = formValue.replace(/(\d{3})(\d{1,2})$/gi, '$1-$2');
      formValue = formValue.replace(/[^\d]+/g, '');
    } else if (formValue.length <= 18) {
      tamanho = 18;
      mask = '99.999.999/9999-99';
      formValue = formValue.replace(/\D/gi, '');
      formValue = formValue.replace(/(\d{2})(\d)/gi, '$1.$2');
      formValue = formValue.replace(/(\d{3})(\d)/gi, '$1.$2');
      formValue = formValue.replace(/(\d{3})(\d)/gi, '$1/$2');
      formValue = formValue.replace(/(\d{3})(\d{1,4})$/gi, '$1-$2');
      formValue = formValue.replace(/(\d{2})(\d{1,2})$/gi, '$1$2');
      formValue = formValue.replace(/[^\d]+/gi, '');
    } else {
      mask = '99.999.999/9999-99';
      tamanho = 18;
      formValue = formValue.replace(/[^\d]+/gi, '');
      formValue = formValue.substring(0, 18);
    }


    this.element.nativeElement.value = this.formatField(formValue, mask, tamanho);
  }

  private formatField(field: string, mask: string, size: number): any {
    if (field.length > size) {
      field = field.substring(0, 18);
    }
    if (!size) { size = 18; }
    let boleanoMascara;
    const exp = /\_|\-|\.|\/|\(|\)|\,|\*|\+|\@|\#|\$|\&|\%|\:| /gi;
    field = field.toString().replace(exp, '');
    if (field.length > 14) {
      field = field.substring(0, 14);
    }
    const campoSoNumeros = field.toString().replace(exp, '');
    let posicaoCampo = 0;
    let NovoValorCampo = '';
    let TamanhoMascara = campoSoNumeros.length;
    for (let i = 0; i < TamanhoMascara; i++) {
        if (i < size && i<mask.length) {
            boleanoMascara = (mask.charAt(i) === '-') || (mask.charAt(i) === '.') || (mask.charAt(i) === '/');
            boleanoMascara = boleanoMascara || mask.charAt(i) === '_';
            boleanoMascara = boleanoMascara || ((mask.charAt(i) === '(') || (mask.charAt(i) === ')') || (mask.charAt(i) === ' '));
            boleanoMascara = boleanoMascara || ((mask.charAt(i) === ',') || (mask.charAt(i) === '*') || (mask.charAt(i) === '+'));
            boleanoMascara = boleanoMascara || ((mask.charAt(i) === '@') || (mask.charAt(i) === '#') || (mask.charAt(i) === ':'));
            boleanoMascara = boleanoMascara || ((mask.charAt(i) === '$') || (mask.charAt(i) === '&') || (mask.charAt(i) === '%'));
          if (boleanoMascara) {
            NovoValorCampo += mask.charAt(i);
            TamanhoMascara++;
          } else {
            NovoValorCampo += campoSoNumeros.charAt(posicaoCampo);
            posicaoCampo++;
          }
        }
    }
    return NovoValorCampo;
  }

}
