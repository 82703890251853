<!-- Sidenav -->
<div class="" (window:resize)="isMobile($event)">
  <app-sidebar [ngClass]="{ 'sidenav fixed-left': isMobileResolution === false }" ></app-sidebar>
  <div class="main-content">
    <!-- Top navbar -->
    <app-navbar></app-navbar>
    <!-- Pages -->
    <div [@routeAnimations]="getRouteAnimationData()">
    <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>

<div class="animacao-loading" *ngIf="gs.animacao" style="z-index:500000 !important;">
  <img src="assets/img/brand/loading.svg">
</div>