import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GlobalService } from 'src/app/providers/global.service';
import { PagamentoService } from 'src/app/services/pagamento.service';
import * as $ from 'jquery';
import { PIX } from 'src/app/models/pagamento';
import { ClipboardService } from 'ngx-clipboard';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-valor-pix',
  templateUrl: './valor-pix.component.html',
  styleUrls: ['./valor-pix.component.scss']
})
export class ValorPixComponent implements OnInit {

  chave = false;
  valor: any;

  qrcode: any;

  constructor(
    private gs: GlobalService, public modalSvc: BsModalService, private pgSvc: PagamentoService,
    private clipboard: ClipboardService, private rV3Svc: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
  }

  continuar() {
    this.valor = $('#txt-valor-pix-saldo').val();//$('.txt-valor').val();

    if (parseFloat(this.valor.toString().replace('.', '').replace('.', '').replace('.', '').replace(',', '.')) < 1) { //< 0.01) {
      // window.app.alerta('Alerta!', 'Para adicionar saldo via PIX o valor mínimo é R$ 1,00', 'Ok');
      this.gs.showNotification('danger', 'Atenção', 'Para adicionar saldo via PIX o valor mínimo é R$ 1,00');
    } else {

      // this.navCtrl.navigateForward('/pix-qrcode-criado',
      //   { queryParams: { pix: JSON.parse(aa), retorno: this.retorno, titulo: this.titulo } });




      let v = this.valor.toString().replace('.', '').replace('.', '').replace('.', '').replace(',', '.');
      let oPix: PIX = {
        valorPagar: 0, valorPix: parseFloat(v), personIdEstabelecimento: null,
        observacao: '', gr: ''
      };


      this.gs.animacao = true;

      //CHAVE RECAPTCHA PixRecarga

      //this.rV3Svc.execute('PixRecarga').subscribe((token: string) => {
        oPix.gr = '';//token;
        // console.log
        this.pgSvc.geraChavePix(oPix).subscribe((r) => {
          // console.log(r);
          // console.log(JSON.stringify(r.obj));
          
          if (r.status === 200) {
            this.qrcode = r.obj;
            this.chave = true;
            if (!r.obj.precisaAutenticar) {
              //this.navCtrl.navigateForward('/pix-qrcode-criado', { queryParams: { pix: r.obj, retorno: this.retorno, titulo: this.titulo } });
            } else {
              // window.app.alerta('Alerta!', r.obj.msgErro, 'Autenticar', 3000, '/perfil-e-senha');
              this.gs.showNotification('danger', 'Alerta!', r.obj.msgErro);
            }
          } else {
            // window.app.alerta('Alerta!', r.msg, 'Ok');
            this.gs.showNotification('danger', 'Alerta!', r.msg);
          }
        }, null, () => {
          this.gs.animacao = false;
        });
      // }, () => {
      //   this.gs.animacao = false;
      // });
    }
  }

  async fechar() {
    this.modalSvc._hideModal(1);
  }

  async copiarChave() {
    let copyText = this.qrcode.chavePix;

    this.clipboard.copyFromContent(copyText)

    this.gs.showNotification('default', 'PIX', 'Chave copiada!', 2000);
  }

}
