<div style="position:fixed;top:0;z-index:-1;background:var(--azul-nivel);width:100%; height:76px;"></div>
<nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-whitex" id="sidenav-main"
  (mouseover)="onMouseEnterSidenav()" (mouseout)="onMouseLeaveSidenav()">
  <perfect-scrollbar>
    <div class="scrollbar-inner">
      <div class="sidenav-header d-flex align-items-center">
        <a class="navbar-brand" [routerLink]="['/dashboard']">
          <!-- <img src="assets/img/brand/red.png" class="navbar-brand-img" alt="..." /> -->
          <img src="assets/img/logo-nivel.png" style="max-height:3rem !important;" class="navbar-brand-img" alt="..." />
        </a>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div class="sidenav-toggler d-noneX mr-1 d-xl-block" data-action="sidenav-unpin" data-target="#sidenav-main"
            (click)="minimizeSidebar()">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-inner">
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
          <!-- Collapse header -->
          <ul class="navbar-nav">
            <li *ngFor="let menuitem of menuItems" class="nav-item">
              <!--If is a single link-->
              <a routerLinkActive="active" [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'"
                class="nav-link">
                <i class="ni {{ menuitem.icontype }}"></i>
                <span class="nav-link-text">{{ menuitem.title }}</span>
              </a>
              <!--If it have a submenu-->
              <a data-toggle="collapse" routerLinkActive="active" *ngIf="menuitem.type === 'sub'"
                (click)="menuitem.isCollapsed = !menuitem.isCollapsed" [attr.aria-expanded]="!menuitem.isCollapsed"
                [attr.aria-controls]="menuitem.collapse" class="nav-link">
                <i class="ni {{ menuitem.icontype }}"></i>
                <span class="nav-link-text">{{ menuitem.title }}</span>
              </a>
              <!--If is function-->
              <a routerLinkActive="active" (click)="alerta('mIndicacao')" style="cursor:pointer;" *ngIf="menuitem.type === 'function_indicacao'"
                class="nav-link">
                <i class="ni {{ menuitem.icontype }}"></i>
                <span class="nav-link-text">{{ menuitem.title }}</span>
              </a>

              <a routerLinkActive="active" (click)="alerta('mSaldo')" style="cursor:pointer;" *ngIf="menuitem.type === 'function_addsaldo'"
                class="nav-link">
                <i class="ni {{ menuitem.icontype }}"></i>
                <span class="nav-link-text">{{ menuitem.title }}</span>
              </a>

              <!--Display the submenu items-->
              <div id="{{ menuitem.collapse }}" class="collapse" *ngIf="menuitem.type === 'sub'"
                [collapse]="menuitem.isCollapsed" [isAnimated]="true">
                <ul class="nav nav-sm flex-column">
                  <li *ngFor="let childitems of menuitem.children" class="nav-item">
                    <!--If is a single link-->
                    <a routerLinkActive="active" [routerLink]="[menuitem.path, childitems.path]" class="nav-link"
                      *ngIf="childitems.type === 'link'">
                      {{ childitems.title }}
                    </a>
                    <!--If it have a submenu-->
                    <a data-toggle="collapse" (click)="childitems.isCollapsed = !childitems.isCollapsed"
                      [attr.aria-expanded]="!childitems.isCollapsed" [attr.aria-controls]="childitems.collapse"
                      *ngIf="childitems.type === 'sub'" class="nav-link">
                      {{ childitems.title }}
                    </a>
                    <!--Display the submenu items-->
                    <div id="{{ childitems.collapse }}" class="collapse" *ngIf="childitems.type === 'sub'"
                      [collapse]="childitems.isCollapsed" [isAnimated]="true">
                      <ul class="nav">
                        <li *ngFor="let childitem of childitems.children" class="nav-item">
                          <!-- <a href="{{javascript:void(0)}}" class="nav-link">
                            {{ childitem.title }}
                          </a> -->
                          <a [routerLink]="[childitems.path, childitem.path]" class="nav-link">
                            {{ childitem.title }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <!-- Divider -->
          <!-- <hr class="my-3" /> -->
          <!-- Heading -->
          <!-- <h6 class="navbar-heading p-0 text-muted">Documentation</h6> -->
          <!-- Navigation -->
          <!-- <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/argon-dashboard-pro-angular/#/documentation/overview?ref=adpa-sidebar-area"
              target="_blank"
            >
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text">Getting started</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/argon-dashboard-pro-angular/#/documentation/colors?ref=adpa-sidebar-area"
              target="_blank"
            >
              <i class="ni ni-palette"></i>
              <span class="nav-link-text">Foundation</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/argon-dashboard-pro-angular/#/documentation/alerts?ref=adpa-sidebar-area"
              target="_blank"
            >
              <i class="ni ni-ui-04"></i>
              <span class="nav-link-text">Components</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/argon-dashboard-pro-angular/#/documentation/charts?ref=adpa-sidebar-area"
              target="_blank"
            >
              <i class="ni ni-chart-pie-35"></i>
              <span class="nav-link-text">Plugins</span>
            </a>
          </li>
        </ul> -->
        </div>
      </div>
    </div>
  </perfect-scrollbar>

</nav>