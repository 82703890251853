<!-- <ng-template #modalForm> -->
<div class=" modal-header">
    <!-- <h6 class=" modal-title" id="modal-title-default">
        Adicionar saldo
    </h6> -->

    <button aria-label="Close" class=" close" data-dismiss="modal" type="button" (click)="fechar()">
        <span aria-hidden="true"> × </span>
    </button>
</div>
<div class=" modal-body p-0">
    <div class=" card bg-secondary border-0 mb-0" *ngIf="!chave">

        <div class=" card-body px-lg-5 px-lg-5">
            <div class=" text-center text-muted mb-4">
                <h4> Para adicionar saldo, basta gerar uma chave PIX e pagar.<br>
                    (O custo é de 1 centavo)
                </h4>
            </div>

            <form role="form">
                <div class="form-group mb-3" [ngClass]="{ focused: focus === true }">
                    <div class="input-group input-group-alternative">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ni ni-money-coins"></i></span>
                        </div>
                        <input class="form-control txt-valor" id="txt-valor-pix-saldo" placeholder="Qual é o valor?" type="tel" maskMoeda />
                    </div>
                </div>

                <div class="text-center">
                    <button type="button" class="btn btn-default my-4" (click)="continuar()">
                        Gerar Chave
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div class=" card bg-secondary border-0 mb-0" *ngIf="chave">

        <div class=" card-body px-lg-5 px-lg-5">
            <div class=" text-center text-muted mb-4">
                <h4> Leia esse QR Code PIX com o aplicativo do seu banco
                </h4>
                <img src="{{qrcode.qRcodePix}}">
                <br>
                <h4>Se for de sua preferência use a chave <i>"copia e cola"</i></h4>
                <h6>{{qrcode.chavePix}} </h6>
                <button type="button" class="btn btn-secondary btn-sm" (click)="copiarChave()">
                    <i class="bi bi-clipboard" style="font-size:1.4em;"></i> copiar chave 
                </button>
            </div>

            
        </div>
    </div>

</div>
<!-- </ng-template> -->