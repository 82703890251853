import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[maskNumero]',
  host: {
    '(keyup)': 'mascara($event)'
  }
})
export class NumeroDirective {

  constructor(public element: ElementRef) { }

  mascara(e) {
    e.preventDefault();

    this.element.nativeElement.maxlength = 9;

    let mask: string;
    let tamanho: number;
    let formValue = e.target.value;

    tamanho = this.element.nativeElement.maxlength;
    mask = ''.padStart(tamanho, '9');
    
    formValue = formValue.replace(/\D/gi, '');
    // formValue = formValue.replace(/(\d{2})(\d)/gi, '$1 $2');
    // formValue = formValue.replace(/(\d{5})(\d)/gi, '$1-$2');
    // formValue = formValue.replace(/(\d{4})(\d)/gi, '$1$2');

    this.element.nativeElement.value = this.formatField(formValue, mask, tamanho);
  }

  private formatField(field: string, mask: string, size: number): any {
    if (field.length > size) {
      field = field.substring(0, size+1);
    }
    if (!size) { size = 99999999999; }
    let boleanoMascara;
    const exp = /\_|\-|\.|\/|\(|\)|\,|\*|\+|\@|\#|\$|\&|\%|\:| /gi;
    const campoSoNumeros = field.toString().replace(exp, '');
    let posicaoCampo = 0;
    let NovoValorCampo = '';
    let TamanhoMascara = campoSoNumeros.length;
    for (let i = 0; i < TamanhoMascara; i++) {
      if (i < size) {
        boleanoMascara = (mask.charAt(i) === '-') || (mask.charAt(i) === '.') || (mask.charAt(i) === '/');
        boleanoMascara = boleanoMascara || mask.charAt(i) === '_';
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === '(') || (mask.charAt(i) === ')') || (mask.charAt(i) === ' '));
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === ',') || (mask.charAt(i) === '*') || (mask.charAt(i) === '+'));
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === '@') || (mask.charAt(i) === '#') || (mask.charAt(i) === ':'));
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === '$') || (mask.charAt(i) === '&') || (mask.charAt(i) === '%'));
        if (boleanoMascara) {
          NovoValorCampo += mask.charAt(i);
          TamanhoMascara++;
        } else {
          NovoValorCampo += campoSoNumeros.charAt(posicaoCampo);
          posicaoCampo++;
        }
      }
    }
    return NovoValorCampo;
  }

}
