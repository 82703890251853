import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { GlobalService } from '../providers/global.service';
@Injectable()
export class MessagingService {
  pushToken = '';
  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging, private gs: GlobalService) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: any) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        try {
          _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        } catch (ex) { }
      }
    )
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // console.log(token);
        this.gs.webToken = token;
        this.pushToken = token;
      },
      (err) => {
        // console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        // console.log("new message received. ", payload);
        if (payload.data && payload.data.saldo) {
          this.gs.usuario.usuario.saldo.saldo = payload.data.saldo;
        }
        this.currentMessage.next(payload);
      })
  }
}