import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[maskPercentual]',
  // host: {
  //   '(keyup)': 'mascara($event)'
  // }
})
export class PercentualDirective {

  constructor(public element: ElementRef) { }

  @HostListener('keyup', ['$event']) OnKeyUp(event) {
    this.mascara(event);
  }

  padLeft(num: number, size: number): string {
    num = parseFloat(num.toString().replace(/\./gi, '').replace(/,/gi, '.'));
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  mascara(e) {
    e.preventDefault();
    const decimal = 2; // config.decimal || this.brmasker.decimal;
    const decimalCaracter = ',';
    const thousand = '.';

    let value = e.target.value;

    while (value[0] === '0' || value[0] === 0) {
      value = value.substring(1);
      value = value.toLocaleString('pt-BR');
    }

    if (value.length < 3) {
      value = this.padLeft(value,3);
      value = value.toLocaleString('pt-BR');
    }

    value = value
      .replace(/\D/gi, '')
      .replace(new RegExp('([0-9]{' + decimal + '})$', 'g'), decimalCaracter + '$1');

    if (value.length === decimal + 1) {
      return '0' + value;
    } else if (value.length > decimal + 2 && value.charAt(0) === '0') {
      return value.substr(1);
    }
    // if (thousand && value.length > (Number(4) + Number(decimal))) {
    //   const valueOne = `([0-9]{3})${decimalCaracter}([0-9]{${decimal}}$)`;
    //   value = value.replace(new RegExp(`${valueOne}`, `g`), `${thousand}$1${decimalCaracter}$2`);
    // }
    // if (thousand && value.length > (Number(8) + Number(decimal))) {
    //   const valueTwo = `([0-9]{3})${thousand}([0-9]{3})${decimalCaracter}([0-9]{${decimal}}$)`;
    //   value = value.replace(new RegExp(`${valueTwo}`, `g`), `${thousand}$1${thousand}$2${decimalCaracter}$3`);
    // }

    this.element.nativeElement.value = value; // this.formatField(formValue, mask, tamanho);
  }

  mascaraxx(e) {
    e.preventDefault();
    const decimal = 2; // config.decimal || this.brmasker.decimal;
    const decimalCaracter = ',';
    const thousand = '.';

    let value = e.target.value;//.replace(/\D/gi, '').replace(new RegExp('([0-9]{' + decimal + '})$', 'g'), decimalCaracter + '$1');


    console.log(value);



    // if (value.replace(/\./, '').replace(/,/, '').length < 4) {
    //   value = value.toLocaleString('pt-BR');
    // }

    value = parseFloat(value.replace(/\./gi, '').replace(/,/gi, '.'));

    console.log(value.toLocaleString('pt-BR', { minimumFractionDigits: 2 }));

    // if (!value || value === '') {
    //   value = 0.00;
    // }

    this.element.nativeElement.value = value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    return;

    if (value.length === decimal + 1) {
      return '0' + value;
    } else if (value.length > decimal + 2 && value.charAt(0) === '0') {
      return value.substr(1);
    }
    if (thousand && value.length > (Number(4) + Number(decimal))) {
      const valueOne = `([0-9]{3})${decimalCaracter}([0-9]{${decimal}}$)`;
      value = value.replace(new RegExp(`${valueOne}`, `g`), `${thousand}$1${decimalCaracter}$2`);
    }
    if (thousand && value.length > (Number(8) + Number(decimal))) {
      const valueTwo = `([0-9]{3})${thousand}([0-9]{3})${decimalCaracter}([0-9]{${decimal}}$)`;
      value = value.replace(new RegExp(`${valueTwo}`, `g`), `${thousand}$1${thousand}$2${decimalCaracter}$3`);
    }

    this.element.nativeElement.value = value; // this.formatField(formValue, mask, tamanho);
  }

  private formatField(field: string, mask: string, size: number): any {
    if (!size) { size = 99999999999; }
    let boleanoMascara;
    const exp = /\_|\-|\.|\/|\(|\)|\,|\*|\+|\@|\#|\$|\&|\%|\:| /gi;
    const campoSoNumeros = field.toString().replace(exp, '');
    let posicaoCampo = 0;
    let NovoValorCampo = '';
    let TamanhoMascara = campoSoNumeros.length;
    for (let i = 0; i < TamanhoMascara; i++) {
      if (i < size) {
        boleanoMascara = (mask.charAt(i) === '-') || (mask.charAt(i) === '.') || (mask.charAt(i) === '/');
        boleanoMascara = boleanoMascara || mask.charAt(i) === '_';
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === '(') || (mask.charAt(i) === ')') || (mask.charAt(i) === ' '));
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === ',') || (mask.charAt(i) === '*') || (mask.charAt(i) === '+'));
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === '@') || (mask.charAt(i) === '#') || (mask.charAt(i) === ':'));
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === '$') || (mask.charAt(i) === '&') || (mask.charAt(i) === '%'));
        if (boleanoMascara) {
          NovoValorCampo += mask.charAt(i);
          TamanhoMascara++;
        } else {
          NovoValorCampo += campoSoNumeros.charAt(posicaoCampo);
          posicaoCampo++;
        }
      }
    }
    return NovoValorCampo;
  }

}
