import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import * as uuid from 'device-uuid';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(
    // @Inject('BASE_API_URL') private baseUrl: string
    private gs: GlobalService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const apiReq = req.clone({ url: `${this.baseUrl}${req.url}` });
    // return next.handle(apiReq);
    // console.log(req);


    const { method, url } = req;
    // console.log(method, url);
    if (url.indexOf('/base_cep') > -1) {
      req = req.clone({ url: environment.cepURL + url.replace('/base_cep', '') });
      req.headers.append('Content-Type', 'application/json');
    } else if (url.indexOf('/base_api') > -1) {
      req = req.clone({
        url: environment.apiUrl + url.replace('/base_api', ''),
        setHeaders: {
          'Content-Type': 'application/json',
          'DI': new uuid.DeviceUUID().get()
        }
      });
      req.headers.append('Content-Type', 'application/json');
    } else if (url.indexOf('/base_auth_api') > -1) {
      req = req.clone({
        url: environment.apiUrl + url.replace('/base_auth_api', ''),
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.gs.usuario.token,
          //'DI':'&&DEVICE_ID_PARA_NAO_VALIDAR||'
          'DI':  new uuid.DeviceUUID().get()
        }
      });

      // req.headers.append('Content-Type', 'application/json');
      // req.headers.append('Authorization', 'Bearer ' + this.gs.usuario.token);

      // console.log(JSON.stringify(req));


    } else if (url.indexOf('/base_upload_auth_api') > -1) {
      req = req.clone({
        url: environment.apiUrl + url.replace('/base_upload_auth_api', ''),
        setHeaders: {
          Authorization: 'Bearer ' + this.gs.usuario.token,
          'DI': new uuid.DeviceUUID().get()
        }
      });

      // req.headers.append('Content-Type', 'application/json');
      // req.headers.append('Authorization', 'Bearer ' + this.gs.usuario.token);

      // console.log(JSON.stringify(req));


    }
    return next.handle(req);
  }
}