import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[maskTelefone]',
  // host: {
  //   '(keyup)': 'mascara($event)'
  // }
})
export class TelefoneDirective {

  constructor(public element: ElementRef) { }

  @HostListener('keyup', ['$event']) OnKeyUp(event) {
    this.mascara(event);
  }

  mascara(e) {
    e.preventDefault();

    this.element.nativeElement.maxLength = 15;
    let mask: string;
    let tamanho: number;
    let formValue = e.target.value;
    if (formValue.length === 15) {
      // this.brmasker.len = 15;
      // this.brmasker.mask = '(99) 99999-9999';
      tamanho = 15;
      mask = '(99) 99999-9999';
      formValue = formValue.replace(/\D/gi, '');
      formValue = formValue.replace(/(\d{2})(\d)/gi, '$1 $2');
      formValue = formValue.replace(/(\d{5})(\d)/gi, '$1-$2');
      formValue = formValue.replace(/(\d{4})(\d)/gi, '$1$2');
    } else if (formValue.length <= 14) {
      // this.brmasker.len = 14;
      // this.brmasker.mask = '(99) 9999-9999';
      tamanho = 14;
      mask = '(99) 9999-9999';
      formValue = formValue.replace(/\D/gi, '');
      formValue = formValue.replace(/(\d{2})(\d)/gi, '$1 $2');
      formValue = formValue.replace(/(\d{4})(\d)/gi, '$1-$2');
      formValue = formValue.replace(/(\d{4})(\d)/gi, '$1$2');
    } else {
      tamanho = 15;
      formValue = formValue.replace(/[^\d]+/gi, '');
      formValue = formValue.substring(0, 15);
    }
    // e.target.value = this.formatField(formValue, mask, tamanho); // this.onInput(formValue);
    this.element.nativeElement.value = this.formatField(formValue, mask, tamanho);
  }

  private formatField(field: string, mask: string, size: number): any {
    if (field.length > size) {
      field = field.substring(0, 15);
    }
    if (!size) { size = 99999999999; }
    let boleanoMascara;
    const exp = /\_|\-|\.|\/|\(|\)|\,|\*|\+|\@|\#|\$|\&|\%|\:| /gi;
    const campoSoNumeros = field.toString().replace(exp, '');
    let posicaoCampo = 0;
    let NovoValorCampo = '';
    let TamanhoMascara = campoSoNumeros.length;
    for (let i = 0; i < TamanhoMascara; i++) {
      if (i < size) {
        boleanoMascara = (mask.charAt(i) === '-') || (mask.charAt(i) === '.') || (mask.charAt(i) === '/');
        boleanoMascara = boleanoMascara || mask.charAt(i) === '_';
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === '(') || (mask.charAt(i) === ')') || (mask.charAt(i) === ' '));
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === ',') || (mask.charAt(i) === '*') || (mask.charAt(i) === '+'));
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === '@') || (mask.charAt(i) === '#') || (mask.charAt(i) === ':'));
        boleanoMascara = boleanoMascara || ((mask.charAt(i) === '$') || (mask.charAt(i) === '&') || (mask.charAt(i) === '%'));
        if (boleanoMascara) {
          NovoValorCampo += mask.charAt(i);
          TamanhoMascara++;
        } else {
          NovoValorCampo += campoSoNumeros.charAt(posicaoCampo);
          posicaoCampo++;
        }
      }
    }
    return NovoValorCampo;
  }

}
