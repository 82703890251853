// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://apipwhomologacao.nivel.com.br',//'https://apipwhomologacao.nivel.com.br',
  // apiUrl: 'https://apipw.nivel.com.br',
  apiUrl: 'https://apiweb.nivel.work',
  //apiUrl: 'http://localhost:1276',
  // apiUrl: 'https://apipw.nivel.com.br',
  // apiUrl: 'https://apiweb.nivel.work',
  cepURL: 'https://viacep.com.br',
  siteKey: '6LcKF7QbAAAAADx-XBAlavjwCWN8mz7JuITXhmMG',
  rV3siteKey: '6LcvTnslAAAAADmSDfZvOJE6JS4C0wb59W8C2LOV'
};

export const firebaseConfig = {
  apiKey: "AIzaSyC_9cjkEt_yQCrUth9f2_G_9LtNqiJLm8o",
  authDomain: "doorness-brasil.firebaseapp.com",
  databaseURL: "https://doorness-brasil.firebaseio.com",
  projectId: "doorness-brasil",
  storageBucket: "doorness-brasil.appspot.com",
  messagingSenderId: "233748629347",
  appId: "1:233748629347:web:57cf76827ad6612b24b556",
  vapidKey: "BGV0ZX3CP7QoZKUz6Es9RNQzy6YmNd-hN78bxS-cvjiY-1WRDwQ7JCfapMU3EylW1FYwEhkYO5cPQdLao4jWlb4"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
